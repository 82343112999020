<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gallery</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Gallery</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="project-area-six pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Gallery</span>
            <h2>Featured Gallery have done</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas non voluptatum aliquid autem minima ut culpa fugit fuga cumque, ullam minus sequi.</p>
        </div>

        <div class="project-wraps">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-1">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-2">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="single-project">
                        <div class="project-image bg-3">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-4">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-8 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-5">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>