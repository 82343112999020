<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Testimonials</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>What Our Clients Say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t1.jpg" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Denial Doe</h2>
                                    <span>Marketer</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t2.jpg" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Evana Doe</h2>
                                    <span>Founder</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t3.jpg" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Smith Doe</h2>
                                    <span>Web Designer</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t4.jpg" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Maria Doe</h2>
                                    <span>Founder CEO</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t5.jpg" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Dew Doe</h2>
                                    <span>Graphic Designer</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t6.jpg" alt="testimonials">
                                </div>
                            </div>
                            
                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Keat Smith</h2>
                                    <span>Designer</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>